/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Chair from '../images/misc/404_chair.png';

const ContainerStyles = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const errorStyle = {
  fontFamily: 'Barlow, sans-serif',
  fontSize: '100px',
  fontWeight: 600,
  position: 'relative',
  bottom: '-75px',
};

const headerStyle = {
  marginTop: '50px',
  fontFamily: 'Barlow',
  fontSize: '24px',
  fontWeight: 'bold',
  textAlign: 'center',
};

const subHeaderStyle = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '18px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.78',
  textAlign: 'center',
  color: '#525a79',
};

export default function NotFound() {
  return (
    <div style={ContainerStyles}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p style={errorStyle}>404</p>
        </div>
        <img
          style={{
            width: '487px',
            height: '270px',
          }}
          src={Chair}
          alt="404_chair"
        />
        <div style={{ maxWidth: '500px' }}>
          <p style={headerStyle}>
            <FormattedMessage
              id="notFound.sorrySomethingIsWrong"
              defaultMessage="Sorry, something is wrong."
            />
          </p>
          <p style={subHeaderStyle}>
            <FormattedMessage
              id="notFound.noAccess"
              defaultMessage="You do not have access to this page or resource for some reason."
            />
          </p>
        </div>
      </div>
    </div>
  );
}
